import './default.css';

import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';
import React from 'react';

import HeroPage from '../components/heropage/HeroPage';
import { Layout } from '../components/layout/Layout';
import { InfoData } from '../interfaces';

interface Props {
  data: InfoData;
}

export default function IndexPage({
  data,
}: RouteComponentProps & Props): JSX.Element {
  return (
    <Layout>
      <HeroPage data={data} />
    </Layout>
  );
}

export const query = graphql`
  query {
    elsovakcina: markdownRemark(fileAbsolutePath: { regex: "/elsovakcina/" }) {
      id
      html
      frontmatter {
        id
        title
      }
    }
    masodikvakcina: markdownRemark(
      fileAbsolutePath: { regex: "/masodikvakcina/" }
    ) {
      id
      html
      frontmatter {
        id
        title
      }
    }
    lemondas: markdownRemark(fileAbsolutePath: { regex: "/lemondas/" }) {
      id
      html
      frontmatter {
        id
        title
      }
    }
    todoList: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/data/informationtexts/todolisttexts/" }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            id
            title
          }
        }
      }
    }
  }
`;
