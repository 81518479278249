import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  forwardRef,
} from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';
import React from 'react';

import { MarkdownDataProps } from '../../interfaces';
import TodoIcon from './TodoIcon';

interface Props {
  item: MarkdownDataProps;
  checked: boolean;
  onClick: (idx: number) => void;
}

const MotionFlex = motion.custom(
  forwardRef((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
    );
    return <Flex ref={ref} {...chakraProps} />;
  }),
);

export default function TodoItem({
  item,
  checked,
  onClick,
}: Props): JSX.Element {
  const variants = {
    checked: { backgroundColor: 'var(--chakra-colors-dpcgreen-500)' },
    unchecked: { backgroundColor: 'var(--chakra-colors-dpcgray-100)' },
  };
  return (
    <Flex my={4} alignItems="flex-start">
      <TodoIcon
        checked={checked}
        onClick={(): void => {
          onClick(+item.id);
        }}
      />
      <AccordionItem
        flexGrow={1}
        border="none"
        backgroundColor="dpcgray.100"
        borderRadius="5px"
        boxShadow="rgb(210, 210, 210) 1px 1px 2px 2px"
        className="default"
      >
        <AccordionButton p={0}>
          <MotionFlex
            p={4}
            alignItems="center"
            w="100%"
            animate={checked ? 'checked' : 'unchecked'}
            variants={variants}
          >
            <Box
              color={checked ? 'white' : 'black'}
              textAlign="left"
              flexGrow={1}
            >
              {item.title}
            </Box>
            <AccordionIcon />
          </MotionFlex>
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Box dangerouslySetInnerHTML={{ __html: item.html }} />
        </AccordionPanel>
      </AccordionItem>
    </Flex>
  );
}
