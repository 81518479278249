import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Image,
} from '@chakra-ui/react';
import { Link } from '@reach/router';
import React from 'react';

import bejaratimg from '../../assets/bejarat.png';
import { InfoData } from '../../interfaces';
import TodoPage from './TodoPage';

interface Props {
  data: InfoData;
}

export default function HeroPage({ data }: Props): JSX.Element {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex
        mt="-3rem"
        w="100%"
        backgroundColor="dpcblue.500"
        color="white"
        py="2rem"
        justifyContent="center"
      >
        <Flex
          width={['90%', null, '80%', '60%']}
          flexDirection="column"
          alignItems="center"
        >
          <Heading as="h1" mb="2rem">
            Ön a DPC oltópontjához jön?
          </Heading>
          <Flex flexDirection={['column', 'row']}>
            <Box mr={[0, '2rem']} mb={['2rem', 0]}>
              Segítse Ön is orvosaink munkáját azáltal, hogy{' '}
              <strong>elektronikusan kitöltött</strong> hozzájárulási
              nyilatkozattal érkezik!
            </Box>
            <Box>
              <Button as={Link} to="/hny" colorScheme="dpcgreen">
                Kitöltöm a nyilatkozatot!
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Box width={['90%', null, '80%', '60%']} py="2rem">
        <Heading as="h1" mb="2rem" textAlign="center">
          Gyorsítsuk fel együtt az oltási folyamatot!
        </Heading>
        <Box>
          Ezen az oldalon tájékozódhat arról, hogy mi a pontos helyszín, mik a
          teendői az oltás előtt, mit hozzon magával az első oltásra, hogyan
          mondhatja le az időpontját, hogy zajlik nálunk az oltási folyamat és
          mi szükséges a második oltáshoz. A következő lépések az Ön oltási
          folyamatának gyorsítását szolgálják.
        </Box>
      </Box>
      <Flex
        w="100%"
        backgroundColor="dpcgray.100"
        py="2rem"
        justifyContent="center"
      >
        <Flex
          width={['90%', null, '80%', '60%']}
          flexDirection={['column', null, 'row']}
        >
          <Flex flexDirection="column" flexGrow={1} mr={[0, null, '2rem']}>
            <Heading as="h2" mb="1rem">
              Mi a pontos helyszín?
            </Heading>
            <Box mb="1rem">
              Szent László telephely:
              <br />
              <strong>
                1097 Budapest, Albert Flórián út 5-7. – 3C pavilon
              </strong>
            </Box>
            <Button
              as="a"
              href="https://www.google.com/maps/dir//Albert+Fl%C3%B3ri%C3%A1n+%C3%BAt+5-7,+Budapest,+1097/@47.4755499,19.0878386,16z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x4741dce0c1db5161:0xb33776c366baae14!2m2!1d19.092216!2d47.47555!3e3"
              target="_blank"
              rel="noreferrer"
              width="15rem"
              mb="1rem"
              colorScheme="dpcgreen"
            >
              Útvonal tervezése
            </Button>
            <Box mb="1rem">
              Központi cím: 1097 Budapest, Nagyvárad tér 1.
              <br />
              Postacím: 1476 Budapest, Pf. 10.
            </Box>
          </Flex>
          <Box width={['100%', '80%', '30%']}>
            <Image src={bejaratimg} alt="bejarat_kep" />
          </Box>
        </Flex>
      </Flex>

      <Box width={['90%', null, '80%', '60%']} py="1rem">
        <Heading as="h1" mb="1rem">
          {data.elsovakcina.frontmatter.title}
        </Heading>
        <Box
          className="default"
          dangerouslySetInnerHTML={{ __html: data.elsovakcina.html }}
        />
      </Box>

      <Box width={['90%', null, '80%', '60%']} mb="2rem">
        <Accordion allowMultiple width="100%">
          <Flex
            justifyContent="space-between"
            flexDirection={['column', null, 'row']}
          >
            <AccordionItem
              border="none"
              width={['100%', null, '49%']}
              height="fit-content"
              mb={['1rem', null, 0]}
              backgroundColor="dpcgray.100"
              borderRadius="5px"
              boxShadow="rgb(210, 210, 210) 1px 1px 2px 2px"
            >
              <AccordionButton p={0}>
                <Flex p={4} alignItems="center" width="100%">
                  <Box
                    as="h1"
                    fontWeight="bold"
                    fontSize="1.25rem"
                    textAlign="left"
                    flexGrow={1}
                  >
                    {data.masodikvakcina.frontmatter.title}
                  </Box>
                  <AccordionIcon />
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box
                  className="default"
                  dangerouslySetInnerHTML={{
                    __html: data.masodikvakcina.html ?? '',
                  }}
                />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem
              border="none"
              width={['100%', null, '49%']}
              height="fit-content"
              backgroundColor="dpcgray.100"
              borderRadius="5px"
              boxShadow="rgb(210, 210, 210) 1px 1px 2px 2px"
            >
              <AccordionButton p={0}>
                <Flex p={4} alignItems="center" width="100%">
                  <Box
                    as="h1"
                    fontWeight="bold"
                    fontSize="1.25rem"
                    textAlign="left"
                    flexGrow={1}
                  >
                    {data.lemondas.frontmatter.title}
                  </Box>
                  <AccordionIcon />
                </Flex>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box
                  className="default"
                  dangerouslySetInnerHTML={{ __html: data.lemondas.html ?? '' }}
                />
              </AccordionPanel>
            </AccordionItem>
          </Flex>
        </Accordion>
      </Box>

      <TodoPage
        markdownData={data.todoList.edges
          .map((t) => {
            return {
              id: t.node.frontmatter.id,
              title: t.node.frontmatter.title,
              html: t.node.html,
            };
          })
          .sort((a, b) => a.id.localeCompare(b.id))}
      />
    </Flex>
  );
}
