import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

interface Props {
  checked: boolean;
  onClick: () => void;
}

export default function TodoIcon({ checked, onClick }:Props) {
  return (
    <Flex mt={4} mr={2} alignItems="center">
      <Flex
        p={1}
        border={checked ? 'solid 3px #1bbb99' : 'solid 2px black'}
        w={8}
        h={8}
        borderRadius="100px"
        justify="center"
        alignItems="center"
        onClick={onClick}
      >
        {checked && <Box w={4} h={4} borderRadius="100px" backgroundColor="#1bbb99" />}
      </Flex>
    </Flex>
  );
}
