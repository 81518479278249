import { Accordion, Box, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import todoListData from '../../data/todolist.json';
import { MarkdownDataProps, TodoItemData } from '../../interfaces';
import TodoItem from './TodoItem';

interface ItemChecks {
  [key: string]: boolean;
}

const localStoragePrefix = 'pation_todo_';

interface Props {
  markdownData: MarkdownDataProps[];
}

export default function TodoPage({ markdownData }: Props): JSX.Element {
  const [checked, setChecked] = useState({} as ItemChecks);
  const [idList, setIdList] = useState([] as number[]);

  useEffect(() => {
    let initialValues = {};
    let ids = [] as number[];
    todoListData.todoItems.forEach((i: TodoItemData) => {
      const value = localStorage.getItem(`${localStoragePrefix}${i.id}`);
      initialValues = { ...initialValues, [i.id]: value === '1' };
      ids = [...ids, i.id];
    });
    setChecked(initialValues);
    setIdList(ids);
  }, []);

  const checkItem = (idx: number): void => {
    const newValue = !(checked[idx] ?? false);
    const indexOfItem = idList.indexOf(idx);
    const valuesBefore = (indexOfItem > -1
      ? idList.slice(0, indexOfItem)
      : []
    ).map((i) => checked[i]);
    const valuesAfter = (indexOfItem > -1
      ? idList.slice(indexOfItem + 1, idList.length)
      : []
    ).map((i) => checked[i]);
    if (
      (newValue && valuesBefore.every((v) => v)) ||
      (!newValue && !valuesAfter.some((v) => v))
    ) {
      setChecked({ ...checked, [idx]: newValue });
      localStorage.setItem(`${localStoragePrefix}${idx}`, newValue ? '1' : '0');
    }
  };

  return (
    <Box width={['90%', null, '80%', '60%']} py="1rem">
      <Heading as="h1" mb="2rem">
        Hogyan zajlik az oltási folyamat?
      </Heading>
      <Accordion allowMultiple>
        {markdownData.map((i: MarkdownDataProps) => (
          <TodoItem
            key={i.id}
            item={i}
            checked={checked[i.id]}
            onClick={checkItem}
          />
        ))}
      </Accordion>
    </Box>
  );
}
